body {
  text-align: center;
  margin: 0;
  background-color: #1f1524;
  /* background-color: #330033; */
  min-width: 350px;
}

h1 {
  font-family: 'Audiowide', cursive;
  font-size: 3rem;
  /* font-size: 5vw; */
  margin-block-end: 0.5rem;
  /* font-family: 'Kdam Thmor Pro', sans-serif; */
}

@media only screen and (max-width: 420px) {
  h1 {
    font-size: 2.3rem;
  }
}

@media only screen and (min-width: 1920px) {
  h1 {
    font-size: 3.5rem;
  }
}

h2 {
  font-family: 'Audiowide', cursive;
  font-size: 2.1rem;
}

h3 {
  /* font-family: 'Titillium Web', sans-serif; */
  font-size: 1.3rem;
  font-weight: 400;
}

@media only screen and (max-width: 420px) {
  h3 {
    font-size: 1rem;
  }
}

.h1-important {
  color: red;
}

.main-block {
  margin: -20px auto 0;
  background-image: url('../../images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 820px;
  text-align: left;
}

.top-headers {
  max-width: 95%;
  color: white;
  text-align: left;
}
